import { Typewriter } from 'react-simple-typewriter'
import { Link } from 'react-router-dom'
const navigation = {
  solutions: [
    { name: 'Marketing ', href: '/servicios' },
    { name: 'Analytics ', href: '/servicios' },
    { name: 'Commerce ', href: '/servicios' },
    { name: 'Insights', href: '/servicios' }

  ],
  support: [
    { name: 'Contacto', href: '/contacto' },
  ],
  company: [
    { name: 'Casos', href: '/casos' },
    { name: 'Servicios', href: '/servicios' },
    { name: 'Nosotros', href: '/nosotros' },
    { name: 'Carreras', href: '/carreras' },
    { name: 'Blog', href: '/blog' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },

    {
      name: 'Twitter',
      href: '/ho',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: '/ho',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },

  ],
}
function Header() {



  return (
    <main>
      <div className="relative px-6 lg:px-8 overflow-hidden bg-slate-950 text-white">
        <div className="mx-auto max-w-full xl:mx-12 xl:pt-40 xl:pb-64 lg:pt-40 lg:pb-48 pt-24 pb-12  ">
          <div>
            <div>
              <h1 className="text-2xl font-semibold tracking-tight pb-16  sm:text-3xl lg:text-6xl text-indigo-600">
                Danitsoft <span className='text-white '>
                  <Typewriter
                    words={['Transforma ideas en exelentes productos digitales.', 'al servicio de usted y de su empresa.', 'Solutions what you needed!']}
                    loop={0}
                    cursor

                    cursorStyle='_'
                    typeSpeed={120}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  /></span>
              </h1>
              <p className="mt-16 text-white text-xl md:text-2xl max-w-3xl leading-8 ">
                Para empezar hoy nuestros servicios varian entre:<br />
                {navigation.solutions.map((service) => (
                  <span key={service.name} className='lg:text-xl text-md font-medium leading-8 text-gray-400 sm:text-center  transition duration-300 ease-in-out border-b-2 border-transparent hover:border-indigo-400'><Link to={service.href}>{service.name} </Link></span>
                ))}
              </p>
              <div className="absolute bottom-1 left-0 flex gap-4 xl:mx-20 mx-6 ">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-100 cursor-pointer hover:text-gray-300">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className=" h-8 w-8" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className='flex md:min-h-full sm:min-h-screen sm:mb-20 sm:mt-28 sm:mr-60 md:mb--20 mb-60'>
              <div class="container">
                <div class="box md:ml-96 md:mt-60 mt-56">
                  <div class="cube"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Header