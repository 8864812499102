import { CheckIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Diseño Responsivo',
    description: 'Nuestros sitios y aplicaciones se adaptan a cualquier dispositivo para una experiencia óptima.',
  },
  { name: 'Integración con Redes Sociales', description: 'Conecta tu plataforma con las redes sociales para una mayor interacción y alcance.' },
  {
    name: 'Seguridad Avanzada',
    description: 'Implementamos medidas sólidas de seguridad para proteger tus datos y la privacidad de tus usuarios.',
  },
  { name: 'Velocidad de Carga Optima', description: 'Garantizamos tiempos de carga rápidos para una experiencia ágil y eficiente' },
  { name: 'Personalización a Medida', description: 'Creamos soluciones adaptadas a las necesidades específicas de cada cliente.' },
  { name: 'Soporte Técnico Profesional', description: 'Ofrecemos asistencia técnica especializada para resolver cualquier inconveniente de manera rápida y efectiva' },
  { name: 'Actualizaciones Regulares', description: ' Mantenemos tus aplicaciones y sitios web al día con las últimas tecnologías y tendencias del mercado.' },
  { name: 'Experiencia de Usuario Intuitiva', description: 'Diseñamos interfaces fáciles de usar para garantizar una navegación fluida y agradable.' },

]

export default function Features() {
  return (
    <div className="bg-white">
      <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold text-indigo-600">Todo lo que necesitas</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">Todo en uno</p>
          <p className="mt-4 text-lg text-gray-500">
            Listado de características ejemplares con las que cuentan nuestros servicios.
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}