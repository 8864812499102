
const incentives = [
  {
    title: 'Disfruta de 24 horas de tu proyecto gratis',
    imageSrc: '../../../dollar.svg',
    description: "Empresas cubanas pueden experimentar nuestros servicios durante un día completo sin compromiso.",
  },
  {
    title: 'Prueba una plantilla gratuita',
    imageSrc: '../../../monitor.svg',
    description: "Elige una plantilla que se adapte a las necesidades de tu empresa y pruébala sin costo alguno.",
  },
  {
    title: 'Actualizaciones y soporte prioritario',
    imageSrc: '../../../package.svg',
    description:
      "Disfruta de actualizaciones regulares en tus proyectos y acceso a soporte técnico prioritario para garantizar que tus aplicaciones y sitios web estén siempre al día y funcionando de manera óptima.",
  },

]

export default function Incentives() {
  return (
    <div className="bg-white">
      <div className="mx-auto lg:mx-12 max-w-full py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="max-w-3xl">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900">
              Beneficios Exclusivos
            </h2>
            <p className="mt-4 text-gray-500 text-lg">
              Explora las ventajas adicionales que ofrecemos a las empresas que eligen nuestros servicios. Estamos comprometidos a brindarte experiencias únicas y herramientas que impulsen el éxito de tu negocio. Descubre cómo podemos hacer que tu experiencia con el desarrollo web y apps sea excepcional.
            </p>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div key={incentive.title} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="h-16 w-16" src={incentive.imageSrc} alt={`imagen ${incentive.title}`} />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">{incentive.title}</h3>
                  <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}