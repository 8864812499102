import { connect } from 'react-redux'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useState, Fragment, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import DotLoader from 'react-spinners/DotLoader'

const solutions = [
  {
    name: 'Productos',
    description: 'Measure actions your users take',
    href: '/casos',
    icon: IconThree,
  },
  {
    name: 'Servicios',
    description: 'Create your own targeted content',
    href: '/servicios',
    icon: IconThree,
  },
  {
    name: 'Nosotros',
    description: 'Keep track of your growth',
    href: '/nosotros',
    icon: IconThree,
  },
  {
    name: 'Trabajos',
    description: 'Keep track of your growth',
    href: '/trabajos',
    icon: IconThree,
  },
  {
    name: 'Blog',
    description: 'Keep track of your growth',
    href: '/blog',
    icon: IconThree,
  },
  {
    name: 'Contacto',
    description: 'Keep track of your growth',
    href: '/contacto',
    icon: IconThree,
  },
]

function Navbar() {

  const [loading, setLoading] = useState(true)

  window.onscroll = function () { scrollFunction() }

  function scrollFunction() {
    if (document.getElementById('navbar')) {
      if (document.body.scrollTop > 1320 || document.documentElement.scrollTop > 1320) {
        document.getElementById('navbar').classList.add('shadow-navbar', 'bg-white');
        document.getElementById('navbar').classList.remove('bg-slate950', 'pb-10')
        document.getElementById('menu').classList.remove('text-white')
        document.getElementById('menu').classList.add('text-black')
      } else {
        document.getElementById('navbar').classList.remove('shadow-navbar', 'bg-white');
        document.getElementById('navbar').classList.add('bg-slate950', 'pb-10')
        document.getElementById('menu').classList.remove('text-black')
        document.getElementById('menu').classList.add('text-white')

      }
    }
  }

  const [open, setOpen] = useState(false)

  return (
    <nav data-scroll data-scroll-id="hey" id='navbar' className='w-full py-6 top-0 transition duration-300 ease-in-out z-40 fixed bg-slate-950 bg-opacity-90 pb-10'>
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to='/' className="ml-4 mt-2 logo-danit sm:text-3xl lg:pr-8 text-xl "> DanITsoft
            {/* <img
              src={'https://bafybeiczl4dcxupma2zeyilkukfl4yge64axnhajd722wxgin62mtts6uy.ipfs.w3s.link/murkivamarketing.png'}
              width={160}
              height={160}
              className=""
            /> */}
          </Link>
          <div id='menu' className="ml-4 mt-2 flex-shrink-0 text-white">
            <NavLink to='/productos' className="text-lg inline-flex font-medium leading-6 border-b-2 border-transparent hover:border-indigo-500 transition duration-300 ease-in-out mx-4">Productos</NavLink>
            <NavLink to='/servicios' className="text-lg inline-flex font-medium leading-6 border-b-2 border-transparent hover:border-indigo-500 transition duration-300 ease-in-out mx-4">Servicios</NavLink>
            <NavLink to='/nosotros' className="text-lg inline-flex font-medium leading-6 border-b-2 border-transparent hover:border-indigo-500  transition duration-300 ease-in-out mx-4">Nosotros</NavLink>
            <NavLink to='/trabajos' className="text-lg inline-flex font-medium leading-6 border-b-2 border-transparent hover:border-indigo-500   transition duration-300 ease-in-out mx-4">Trabajos</NavLink>
            <NavLink to='/blog' className="text-lg inline-flex font-medium leading-6 border-b-2 border-transparent hover:border-indigo-500   transition duration-300 ease-in-out mx-4">Blog</NavLink>
            <NavLink to='/contacto' className="text-lg inline-flex font-medium leading-6 border-b-2 border-transparent hover:border-indigo-500   transition duration-300 ease-in-out mx-4">Contacto</NavLink>

            <Link
              to="/contacto"
              className="inline-flex ml-12 items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-900 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2"
            >
              Solicitar
              <DotLoader className="ml-3 -mr-1 h-5 w-5" loading={loading} size={20} color="#f2f2f2" />
            </Link>
          </div>
        </div>
        <div className="-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to='/' className="ml-4 mt-2">
            <img
              src={'https://bafybeiczl4dcxupma2zeyilkukfl4yge64axnhajd722wxgin62mtts6uy.ipfs.w3s.link/murkivamarketing.png'}
              width={160}
              height={160}
              className=""
            />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">


            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
                                ${open ? '' : 'text-opacity-90'}
                                focus:ring-none focus:outline-none`}
                  >
                    {
                      open ?
                        <i className='bx bx-x text-4xl'></i>
                        :
                        <i className='bx bx-menu text-4xl'></i>
                    }
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-32 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                <item.icon aria-hidden="true" />
                              </div>
                              <div className="ml-4">
                                <p className="text-sm font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="bg-gray-50 p-4">
                          <a
                            href="##"
                            className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <span className="flex items-center">
                              <span className="text-sm font-medium text-gray-900">
                                Documentation
                              </span>
                            </span>
                            <span className="block text-sm text-gray-500">
                              Start integrating products and tools
                            </span>
                          </a>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {

})(Navbar)

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
      <rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
      <rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
      <rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
      <rect x="29" y="16" width="2" height="20" fill="#FB923C" />
      <rect x="33" y="12" width="2" height="24" fill="#FB923C" />
    </svg>
  )
}