import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';


import AnimatedRoutes from 'Routes';


function App() {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Murkiva | Software Agency</title>
        <meta name="description" content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones." />
        <meta name="keywords" content='agencia de software, agencia de marketing, creacion de pagina web' />
        <meta name="robots" content='all' />
        <link rel="canonical" href="https://www.murkiva.com/" />
        <meta name="author" content='Murkiva' />
        <meta name="publisher" content='Murkiva' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Danitsoft Solutions | Agencia de Software' />
        <meta property="og:description" content='Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones web.' />
        <meta property="og:url" content="https://danitsoftsolutions.vercel.app/" />
        <meta property="og:image" content='https://www.pythonanywhere.com/user/Danit/files/home/Danit/API_porfolio/media/Logo.jpeg' />

        <meta name="twitter:title" content='Danitsoft Solutions | Agencia de Software' />
        <meta
          name="twitter:description"
          content='Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones web.'
        />
        <meta name="twitter:image" content='https://www.pythonanywhere.com/user/Danit/files/home/Danit/API_porfolio/media/Logo.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>
          <AnimatedRoutes />
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
